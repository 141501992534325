<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4> Manage inventory item stock</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ Inventory item stock
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn class="mt-4 btn btn-primary" style="color: #fff" @click="addInventoryItemStock" v-if="checkIsAccessible('inventory-item-stock', 'create')">
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                        Add inventory item stock
                                    </v-btn>&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" md="4">
                                    <v-autocomplete
                                        :items="inventoryItems"
                                        outlined 
                                        label="Inventory item"
                                        dense
                                        clearable
                                        @input="search.inventory_item_id = $event !== null ? $event : ''"
                                        v-model="search.inventory_item_id"
                                        item-text="title"
                                        item-value="id"
                                        v-on:keyup.enter="searchInventoryItemStocks()"
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-menu
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        min-width="auto"
                                        offset-y
                                        transition="scale-transition"
                                        v-model="menu"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            append-icon="mdi-calendar"
                                            clearable
                                            dense
                                            outlined
                                            readonly 
                                            v-bind="attrs"
                                            v-model="search.purchased_date"
                                            v-on="on"
                                        >
                                            <template v-slot:label>
                                                Purchased date
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        @input="menu = false"
                                        v-model="search.purchased_date"
                                    ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                
                                <v-col cols="12" md="4">
                                    <v-btn :loading="loading" @click.prevent="searchInventoryItemStocks()"
                                        class="btn btn-primary w-35 float-right" dark>
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>
                        <div class="">
                            <v-skeleton-loader v-if="loading" type="table-thead">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
                            <table class="table" v-if="!loading">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3 wrap-column">Title</th>
                                        <th class="px-3 wrap-column">Stock</th>
                                        <th class="px-3 wrap-column">Receipt</th>
                                        <th class="px-3 wrap-column">Purchased date</th>
                                        <!-- <th class="pr-3">Added by</th> -->
                                        <th class="pr-3 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="inventoryItemStocks.length > 0" v-for="(item, index) of inventoryItemStocks" :key="index">
                                        <td class="px-3 wrap-column">
                                            {{ item.inventory_item_title ? item.inventory_item_title : '-' }}
                                        </td>
                                        <td class="px-3 wrap-column">
                                            <strong>Quantity: </strong>{{ item.qty ? item.qty : '-'}}
                                            <div class="mt-2">
                                                <strong>Price: </strong>{{ item.price ? item.price : '-'}}
                                            </div>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            <div class="my-2" v-if="item.receipt_image_path">
                                                <a href="#">
                                                    <img style="width: 100px; height:100px; object-fit: cover; border: 1px solid #efefef;" :src="item.receipt_image_path['thumb']">
                                                </a>
                                            </div>
                                            <div v-else>
                                                -
                                            </div>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            {{ item.purchased_date ? item.purchased_date_formatted : '-'}}
                                        </td>
                                        <!-- <td class="px-3 wrap-column">
                                            {{ item.added_by_full_name ? item.added_by_full_name : '-' }}
                                        </td> -->
                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('inventory-item-stock', 'edit')">
                                                        <a href="#" class="navi-link" @click="editInventoryItemStock(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text"> Edit </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('inventory-item-stock', 'delete')">
                                                        <a href="#" class="navi-link" @click="deleteInventoryItemStock(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-delete"></i>
                                                            </span>
                                                            <span class="navi-text"> Delete </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr v-if="inventoryItemStocks.length == 0">
                                        <td class="text-center  px-3" colspan="5">
                                          <strong>No data available to display.</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination 
                                v-show="inventoryItemStocks.length > 0" 
                                @input="getInventoryItemStocks" 
                                class="pull-right mt-7" 
                                v-model="page"
                                :total-rows="total" 
                                :per-page="perPage" 
                                first-number 
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </v-app>
</template>

<script>
import InventoryItemStockService from "@/core/services/inventory-item-stock/InventoryItemStockService";
import InventoryItemService from "@/core/services/inventory-item/InventoryItemService";

const inventoryItemStock = new InventoryItemStockService();
const inventoryItem = new InventoryItemService();

export default {
    data() {
        return {
            search: {
                inventory_item_id: null,
                purchased_date: ''
            },
            loading: false,
            inventoryItems: [],
            page: null,
            perPage: null,
            total: null,
            menu: false,
            addressBooks: [],
            categories: [],
            inventoryItemStocks: []
        }
    },
    methods: {
        searchInventoryItemStocks(){
            this.page = 1;
            this.getInventoryItemStocks();
        },  
        getInventoryItemStocks() {
            this.loading = true;
            inventoryItemStock
                .paginate(this.search, this.page)
                .then(response => {
                    this.inventoryItemStocks = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                });
        },
        addInventoryItemStock() {
            this.$router.push({
                name: 'create-inventory-item-stock'
            });
        },
        editInventoryItemStock(id) {
            this.$router.push({
                name: 'edit-inventory-item-stock',
                params: {
                    inventoryItemStockId: id
                }
            });
        },
        deleteInventoryItemStock(id){
            this.$confirm({
                message: `Are you sure you want to delete?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        inventoryItemStock
                        .delete(id)
                        .then(response => {
                            this.$snotify.success('Successfully deleted')
                            this.getInventoryItemStocks();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        },
        getInventoryItems() {
            inventoryItem
            .all()
            .then(response => {
                this.inventoryItems = response.data.inventoryItems;
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
    },
    mounted() {
        this.getInventoryItemStocks();
        this.getInventoryItems();
    }
}
</script>